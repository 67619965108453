import React from "react";
import styles from "../../styles/GameModes.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

function GameModes({ refer }) {
  const [isOpen, setIsOpen] = React.useState(false);
  let navigate = useNavigate();

  return (
    <div className={`container py-5 mt-5 glass-card ${styles.container}`} ref={refer}>
      <div className="row">
        <motion.div
          className={`col-12 text-center text-white my-4 ${styles.title}`}
          id="lista-serwerow"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Nasze tryby
        </motion.div>
        
        <div className={styles.gameModesGrid}>
          <motion.div
            className={styles.column}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <div className={styles.cardContainer}>
              <div
                className={styles.videoWrapper}
                onClick={() => navigate("/backrooms")}
              >
                <video 
                  autoPlay 
                  muted 
                  loop 
                  alt="serwer minecraft backrooms jbwm"
                  className={styles.resizedVideo}
                >
                  <source
                    src={require("../../assets/vids/backrooms.mp4")}
                    type="video/mp4"
                  />
                </video>
                <div className={styles.gamemodeName}>
                  <div>BACKROOMS</div>
                </div>
              </div>
            </div>
          </motion.div>
          
          <motion.div
            className={styles.column}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <div className={styles.cardContainer}>
              <div
                className={styles.videoWrapper}
                onClick={() => navigate("/skyblock")}
              >
                <video 
                  autoPlay 
                  muted 
                  loop 
                  alt="serwer minecraft skyblobk jbwm"
                  className={styles.resizedVideo}
                >
                  <source
                    src={require("../../assets/vids/skyblock-2.mp4")}
                    type="video/mp4"
                  />
                </video>
                <div className={styles.gamemodeName}>
                  <div>SKYMMO</div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
              className={styles.extraModes}
            >
              <div className={styles.gameModesGrid}>
                <motion.div
                  className={styles.column}
                  variants={{
                    collapsed: { opacity: 0, y: 20 },
                    open: { opacity: 1, y: 0 },
                  }}
                  transition={{ duration: 0.3, delay: 0.1 }}
                >
                  <div className={styles.cardContainer}>
                    <div
                      className={styles.videoWrapper}
                      onClick={() => navigate("/minez")}
                    >
                      <video 
                        autoPlay 
                        muted 
                        loop 
                        alt="serwer minecraft minez jbwm"
                        className={styles.resizedVideo}
                      >
                        <source
                          src={require("../../assets/vids/minez.mp4")}
                          type="video/mp4"
                        />
                      </video>
                      <div className={styles.gamemodeName}>
                        <div>MINEZ</div>
                      </div>
                    </div>
                  </div>
                </motion.div>
                
                <motion.div
                  className={styles.column}
                  variants={{
                    collapsed: { opacity: 0, y: 20 },
                    open: { opacity: 1, y: 0 },
                  }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                >
                  <div className={styles.cardContainer}>
                    <div
                      className={styles.videoWrapper}
                      onClick={() => navigate("/duels")}
                    >
                      <img
                        src={require("../../assets/img/duels.webp")}
                        width="100%"
                        alt="serwer minecraft duels jbwm"
                      />
                      <div className={styles.gamemodeName}>
                        <div>DUELS</div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </motion.section>
          )}
        </AnimatePresence>
        
        <div className="d-flex justify-content-center">
          <motion.button
            onClick={() => setIsOpen(!isOpen)}
            className={styles.toggleButton}
            whileHover={{ scale: 1.05, backgroundColor: "rgba(255, 128, 0, 0.9)" }}
            whileTap={{ scale: 0.95 }}
          >
            {isOpen ? "Pokaż mniej trybów" : "Pokaż więcej trybów"}
          </motion.button>
        </div>
      </div>
    </div>
  );
}

export default GameModes;
