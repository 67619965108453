import { useNavigate, useParams } from "react-router-dom";
import PlayerTop from "./PlayerTop";
import Statistic from "./Statistic";
import styles from "./UserStats.module.scss";
import { useEffect, useState } from "react";

function UserStats({ gameMode }) {
  const navigate = useNavigate();

  const { username } = useParams();

  const [error, setError] = useState();
  const [userTopStats, setUserTopStats] = useState();
  const [userPersonalStats, setUserPersonalStats] = useState();

  useEffect(() => {
    fetch(
      `https://backend.jbwm.pl/api/stats/${gameMode.toLowerCase()}/rank/${username}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setError(response.status);
        }
      })
      .then((data) => setUserTopStats(data));
  }, [gameMode, username]);

  useEffect(() => {
    fetch(
      `https://backend.jbwm.pl/api/stats/${gameMode.toLowerCase()}/${username}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setError(response.status);
        }
      })
      .then((data) =>
        setUserPersonalStats({
          ...data,
          professions: JSON.parse(data.professions),
        })
      );
  }, [gameMode, username]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("pl-PL", {
      year: "numeric",
      month: "long",
      day: "numeric"
    });
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 text-white mt-5">
      {error ? (
        <div className={styles.playerCard}>
          <h2 className="text-center mb-4">
            {error === 404
              ? "Nie znaleziono gracza!"
              : "Wystąpił nieznany błąd!"}
          </h2>

          <div className="text-center">
            <button
              type="button"
              className={styles.backButton}
              onClick={() => navigate(-1)}
            >
              Wróć
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.playerCard}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={`https://minotar.net/avatar/${username}/100`}
                alt="avatar"
                className={styles.avatar}
              />

              <h2 className={`${styles.username} mt-4`}>{username}</h2>

              {userPersonalStats && (
                <>
                  <h5 className={styles.playerClass}>{userPersonalStats["class"]}</h5>
                  <p className="text-muted">
                    Ostatnie logowanie: {formatDate(userPersonalStats["lastLogin"])}
                  </p>
                </>
              )}

              <button
                type="button"
                className={styles.backButton}
                onClick={() => navigate(-1)}
              >
                Wróć
              </button>
            </div>
          </div>

          <div className={`${styles.statsContainer} d-flex flex-column flex-md-row justify-content-center align-items-start gap-4`}>
            {userPersonalStats && (
              <div className={`${styles.statsSection}`}>
                <h3>Statystyki</h3>
                <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                  <Statistic
                    name="level"
                    level={userPersonalStats["level"]}
                    exp={userPersonalStats["experience"]}
                  />

                  {Object.entries(userPersonalStats["professions"]).map(
                    ([key, value], index) => {
                      return (
                        <Statistic
                          key={index}
                          name={key}
                          level={value["level"]}
                          exp={value["exp"]}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}

            {userTopStats && (
              <div className={`${styles.statsSection}`}>
                <h3>Rankingi</h3>
                <div className="d-flex flex-wrap justify-content-center gap-3">
                  {Object.entries(userTopStats["rankingRecord"]).map(
                    ([key, value], index) => {
                      return (
                        <PlayerTop
                          key={index}
                          category={userTopStats["translations"][key]}
                          place={value}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserStats;
