import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Tiktok from "../Tiktok";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation, Pagination } from "swiper";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function TiktokSwiper() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const [tiktoks, setTiktoks] = React.useState([]);

  async function fetchTiktok() {
    try {
      const data = await fetch("https://backend.jbwm.pl/api/tiktok/videos");
      const result = await data.json();

      setTiktoks(result);
    } catch (e) {
      console.log(`TIKTOK ERROR - ${e}`)
    }
  }

  React.useEffect(() => {
    fetchTiktok();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let { width } = windowDimensions;

  // Ensure we have enough height for all tiktoks to be scrollable
  React.useEffect(() => {
    // Add a small delay to allow component to fully render
    const timer = setTimeout(() => {
      const swiperContainer = document.querySelector('.swiper');
      if (swiperContainer) {
        // Make sure the height allows scrolling
        swiperContainer.style.height = 'auto';
        swiperContainer.style.minHeight = '600px';
      }
    }, 500);
    
    return () => clearTimeout(timer);
  }, [tiktoks]);

  return (
    <div style={{ overflowY: 'auto', maxHeight: '800px', paddingBottom: '20px' }}>
      <Swiper
        spaceBetween={0}
        slidesPerView={width < 1400 ? 1 : 2}
        loop={false}
        navigation={true}
        pagination={{
          enabled: true,
        }}
        modules={[Autoplay, Navigation, Pagination]}
        style={{ height: 'auto', overflow: 'visible' }}
      >
        {tiktoks
          ?.filter((item, index) => index < 11)
          .map((item) => (
            <SwiperSlide key={item} style={{ height: 'auto', overflow: 'visible', marginBottom: '40px' }}>
              <Tiktok vidId={item} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default TiktokSwiper;
