import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown, faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import SocialBox from "../social-box/SocialBox";
import { faDiscord, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";

function PageTitle({ scrollToFunc, coords }) {
  const [copied, setCopied] = useState(false);

  const copyIpToClipboard = () => {
    navigator.clipboard.writeText("jbwm.pl");
    setCopied(true);
    
    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      <div
        className="row d-flex align-items-center justify-content-between"
        style={{
          padding: "100px 0 80px 0",
        }}
      >
        <div className="col-12 col-lg-6">
          <h1 style={{ color: "#fff", fontWeight: 800 }}>
            Dołącz na najlepszą sieć serwerów minecraft w Polsce!
          </h1>

          <p style={{ color: "#D8D8D8", fontWeight: 300, fontSize: 20 }}>
            Nasza sieć oferuje rozgrywkę na największym poziomie, autorskie
            oryginalne tryby na wiele emocjonujących godzin rozgrywki!
          </p>

          <div className="d-flex justify-content-start mb-4">
            <motion.button 
              onClick={copyIpToClipboard}
              className="btn btn-lg"
              style={{ 
                backgroundColor: copied ? "#28a745" : "#FF7A00", 
                borderColor: copied ? "#28a745" : "#FF7A00",
                color: "#fff",
                fontWeight: 600,
                padding: "10px 30px",
                borderRadius: "8px",
                textDecoration: "none",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                transition: "background-color 0.3s ease, border-color 0.3s ease"
              }}
              whileHover={{ 
                scale: 1.05,
                backgroundColor: copied ? "#218838" : "#FF8C1A",
                boxShadow: "0 6px 10px rgba(0, 0, 0, 0.15)"
              }}
              whileTap={{ scale: 0.98 }}
              animate={{ 
                backgroundColor: copied ? "#28a745" : "#FF7A00",
                borderColor: copied ? "#28a745" : "#FF7A00" 
              }}
              transition={{ duration: 0.3 }}
            >
              <span>{copied ? "Skopiowano!" : "Skopiuj IP"}</span>
              <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
            </motion.button>
          </div>

          <div className="d-flex flec-row justify-content-center mb-5">
            <SocialBox icon={faDiscord}
              link="https://discord.com/invite/NRDwwyk" />

            <SocialBox icon={faTiktok}
              link="https://www.tiktok.com/@jbwm.pl" />

            <SocialBox icon={faYoutube}
              link="https://www.youtube.com/@JBWMpl" />
          </div>
        </div>
        <div
          className="col-12 col-lg-6 d-flex justify-content-center"
          style={{
            transform: `translate(${coords.x / 50}px,${coords.y / 50}px)`,
          }}
        >
          <img
            src={require("../../assets/img/render.webp")}
            className="float-end blackhead-image-skin-hero-es"
            alt="serwery minecraft"
          />
        </div>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ marginBottom: 180 }}
      >
        <motion.div
          whileHover={{ y: 0 }}
          animate={{ y: -15 }}
          transition={{
            type: "spring",
            stiffness: 100,
            repeat: Infinity,
            repeatType: "reverse",
            duration: 1,
          }}
          style={{ background: "transparent", cursor: "pointer" }}
          onClick={scrollToFunc}
        >
          <FontAwesomeIcon
            icon={faAnglesDown}
            style={{ color: "#fff", fontSize: 40 }}
          />
        </motion.div>
      </div>
    </>
  );
}

export default PageTitle;
