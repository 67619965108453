import React from "react";
import { motion } from "framer-motion";

function Dynmap({ title, src }) {
    return (
        <div className="dynmap-container container py-5">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="dynmap-content"
            >
                <div className="text-center mb-4">
                    <motion.h1 
                        className="fancy text-white mb-3"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ delay: 0.2, duration: 0.4 }}
                    >
                        <span>{title}</span>
                    </motion.h1>
                    <motion.p 
                        className="text-light mb-4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.8 }}
                        transition={{ delay: 0.4, duration: 0.4 }}
                    >
                        Interaktywna mapa świata serwera
                    </motion.p>
                </div>

                <motion.div 
                    className="map-wrapper"
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    whileHover={{ boxShadow: "0 15px 30px rgba(0, 0, 0, 0.25)" }}
                    style={{
                        borderRadius: "12px",
                        overflow: "hidden",
                        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
                        border: "2px solid rgba(255, 255, 255, 0.1)"
                    }}
                >
                    <iframe 
                        className="dynmap-iframe"
                        title={title}
                        src={src}
                        style={{
                            height: "70vh",
                            width: "100%",
                            border: "none",
                            display: "block"
                        }}
                        loading="lazy"
                    />
                </motion.div>

                <motion.div 
                    className="map-info mt-4 p-3 text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.6, duration: 0.4 }}
                    style={{
                        background: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "8px",
                        backdropFilter: "blur(5px)"
                    }}
                >
                    <p className="text-light mb-0">
                        Mapa aktualizuje się na żywo. Możesz przybliżać, oddalać i przesuwać mapę.
                    </p>
                </motion.div>
            </motion.div>
        </div>
    );
}

export default Dynmap;
