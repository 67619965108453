import capitalizeFirstLetter from "../../utils/functions";
import styles from "./Statistic.module.scss";

function Statistic({ name, level, exp }) {
  // Determine if progress is low (below 20%)
  const isLowProgress = exp < 20;
  
  return (
    <div className={styles.statisticContainer}>
      <div className={styles.statName}>{capitalizeFirstLetter(name)}</div>

      <div className={styles.levelInfo}>
        <div className={styles.levelNumber}>{level}</div>

        <div className={styles.progressContainer}>
          <div 
            className={styles.progressBar} 
            style={{ width: `${exp}%` }}
          >
            {!isLowProgress && (
              <span className={styles.progressText}>{`${exp}%`}</span>
            )}
          </div>
          {isLowProgress && (
            <span className={styles.externalProgressText}>{`${exp}%`}</span>
          )}
        </div>

        <div className={styles.nextLevel}>{level + 1}</div>
      </div>
    </div>
  );
}

export default Statistic;
