import React from "react";
import styles from "./Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faDiscord, 
  faFacebook, 
  faYoutube, 
  faTiktok 
} from "@fortawesome/free-brands-svg-icons";
import { 
  faEnvelope, 
  faServer, 
  faGamepad, 
  faLink, 
  faHeart 
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Footer({ whyUs }) {
  const navigate = useNavigate();
  
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footerWrapper}>
      {whyUs && (
        <div className={styles.whyUsSection}>
          <div className={styles.whyUsContent}>
            <h2 className={styles.whyUsHeading}>
              <FontAwesomeIcon icon={faHeart} className={styles.whyUsIcon} />
              Dlaczego my?
            </h2>
            <p className={styles.whyUsParagraph}>
              Staramy stworzyć się coś nowego, oryginalnego aby odświeżyć
              Polskiego minecrafta. Za długo jesteśmy zalewani tymi samymi
              serwerami bez większego wkładu. Uruchamiamy nowe przełomowe tryby,
              tworzymy najlepsze community. Jeśli szukasz serwera na dłużej dobrze
              trafiłeś! Autorskie pluginy, budowle oraz tryby zapewnią Ci rozrywkę
              na dłuuugie godziny. Wpadnij i sam oceń!
            </p>
          </div>
        </div>
      )}
      
      <div className={styles.footerContent}>
        <div className={styles.footerGrid}>
          <div className={styles.footerBranding}>
            <img 
              src={require("../../assets/img/logo.webp")} 
              alt="JBWM Logo" 
              className={styles.footerLogo}
              onClick={() => handleNavigation("/")}
            />
            <p className={styles.copyright}>
              &copy; {currentYear} JBWM<br />
              Wszelkie prawa zastrzeżone.
            </p>
            <div className={styles.socialLinks}>
              <a 
                href="https://discord.com/invite/NRDwwyk" 
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Discord"
                className={styles.socialIcon}
              >
                <FontAwesomeIcon icon={faDiscord} />
              </a>
              <a 
                href="https://www.facebook.com/Jbwmpl/" 
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
                className={styles.socialIcon}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a 
                href="#" 
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Email"
                className={styles.socialIcon}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </div>
          </div>
          
          <div className={styles.footerLinks}>
            <h3 className={styles.footerHeading}>
              <FontAwesomeIcon icon={faLink} className={styles.footerHeadingIcon} />
              Szybkie linki
            </h3>
            <ul>
              <li onClick={() => handleNavigation("/")}>Strona główna</li>
              <li onClick={() => handleNavigation("/regulamin")}>Regulamin</li>
              <li>
                <a 
                  href="https://jbwm.pl/sklep/" 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sklep
                </a>
              </li>
              <li onClick={() => handleNavigation("/statystyki")}>Statystyki</li>
              <li onClick={() => handleNavigation("/mapa-minez")}>Minez Dynmap</li>
              <li onClick={() => handleNavigation("/mapa-skymmo")}>Mapa SkyMMO</li>
              <li onClick={() => handleNavigation("/lista-banow")}>Lista banów</li>
            </ul>
          </div>
          
          <div className={styles.footerLinks}>
            <h3 className={styles.footerHeading}>
              <FontAwesomeIcon icon={faServer} className={styles.footerHeadingIcon} />
              Wspieramy wersje
            </h3>
            <ul>
              <li>
                <a href="https://topkaminecraft.pl/version/MC_1_19" target="_blank" rel="noopener noreferrer">
                  Minecraft 1.19
                </a>
              </li>
              <li>
                <a href="https://topkaminecraft.pl/version/MC_1_20" target="_blank" rel="noopener noreferrer">
                  Minecraft 1.20
                </a>
              </li>
              <li>
                <a href="https://topkaminecraft.pl/version/MC_1_21" target="_blank" rel="noopener noreferrer">
                  Minecraft 1.21
                </a>
              </li>
            </ul>
          </div>
          
          <div className={styles.footerLinks}>
            <h3 className={styles.footerHeading}>
              <FontAwesomeIcon icon={faGamepad} className={styles.footerHeadingIcon} />
              Nasze serwery
            </h3>
            <ul>
              <li>
                <a href="https://topkaminecraft.pl/category/skyblock" target="_blank" rel="noopener noreferrer">
                  Serwery Skyblock
                </a>
              </li>
              <li>
                <a href="https://topkaminecraft.pl/category/survival" target="_blank" rel="noopener noreferrer">
                  Serwery Survival
                </a>
              </li>
              <li>
                <a href="https://topkaminecraft.pl/category/bedwars" target="_blank" rel="noopener noreferrer">
                  Serwery Bedwars
                </a>
              </li>
              <li>
                <a href="https://topkaminecraft.pl/category/duels" target="_blank" rel="noopener noreferrer">
                  Serwery Duels
                </a>
              </li>
              <li>
                <a href="https://topkaminecraft.pl/category/smp" target="_blank" rel="noopener noreferrer">
                  Serwery SMP
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className={styles.footerBottom}>
        <div>Dołącz do naszej społeczności już dziś! IP: mc.jbwm.pl</div>
      </div>
    </footer>
  );
}

export default Footer;
