import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Broadcast.module.scss';

const Broadcast = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  // Check localStorage on component mount to determine if the broadcast should be shown
  useEffect(() => {
    const broadcastClosed = localStorage.getItem('backroomsBroadcastClosed');
    if (!broadcastClosed) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    // Save to localStorage that user has closed the broadcast
    localStorage.setItem('backroomsBroadcastClosed', 'true');
    setIsVisible(false);
  };

  const handleClick = () => {
    navigate('/backrooms');
    handleClose();
  };

  const handleHighlightClick = (e) => {
    e.preventDefault();
    navigate('/backrooms');
    handleClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.broadcastContainer}>
      <div className={styles.broadcastContent}>
        <span className={styles.message}>
          Odkryj <a href="/backrooms" onClick={handleHighlightClick} className={styles.highlight}>Backrooms</a> - nasz nowy <a href="/backrooms" onClick={handleHighlightClick} className={styles.highlight}>flagowy tryb</a>! Eksploruj tajemnicze poziomy, walcz z potworami i przetrwaj w labiryncie.
        </span>
        <div className={styles.buttons}>
          <button 
            className={styles.joinButton} 
            onClick={handleClick}
          >
            Sprawdź
          </button>
          <button 
            className={styles.closeButton} 
            onClick={handleClose}
          >
            ✕
          </button>
        </div>
      </div>
    </div>
  );
};

export default Broadcast; 