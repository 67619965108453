import React from "react";
import GamemodeInfo from "../../components/gamemode-info/GamemodeInfo";
import styles from "./Statspage.module.scss";

function Statspage() {
  return (
    <div className={styles.statsPageContainer}>
      <div className={styles.headerSection}>
        <h1 className={styles.pageTitle}>
          <span>Statystyki</span>
        </h1>
        <p className={styles.pageDescription}>
          Sprawdź swoje osiągnięcia i porównaj się z innymi graczami
        </p>
      </div>

      <div className={styles.gamemodesContainer}>
        <div className={styles.gamemodeCard}>
          <GamemodeInfo
            title="SkyMMO"
            type="video"
            src={require("../../assets/vids/skyblock-2.mp4")}
            destination="/statystyki-SkyMMO"
          />
          <div className={styles.gamemodeDescription}>
            <h3>SkyMMO</h3>
            <p>Sprawdź swoje statystyki w trybie SkyMMO, poziomy profesji i rankingi.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statspage;
