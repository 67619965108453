import capitalizeFirstLetter from "../../utils/functions";
import styles from "./PlayerTop.module.scss";

function PlayerTop({ category, place }) {
  // Determine medal class based on ranking
  let medalClass = "";
  if (place === 1) medalClass = styles.rank1;
  else if (place === 2) medalClass = styles.rank2;
  else if (place === 3) medalClass = styles.rank3;
  
  return (
    <div className={styles.topWrapper}>
      <div className={`${styles.topPosition} ${medalClass}`}>
        {place <= 3 ? (
          <span className={styles.medal}>#{place}</span>
        ) : (
          <span>#{place}</span>
        )}
      </div>
      <div className={styles.category}>
        {capitalizeFirstLetter(category)}
      </div>
    </div>
  );
}

export default PlayerTop;
