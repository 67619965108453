import React from "react";
import Herosection from "../../components/hero-section/Herosection";
import styles from "../../styles/Homepage.module.scss";
import ServerInfo from "../../components/hero-section/ServerInfo";
import SocialMedia from "../../components/hero-section/SocialMedia";
// import Broadcast from "../../components/broadcast/Broadcast";
// import DocumentMeta from "react-document-meta";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

// const meta = {
//   title: "Innowacyjna sieć serwerów minecraft jbwm.pl",
//   description:
//     "Najlepsza sieć serwerów minecraft w Polsce. Wersje 1.8 1.16 1.18 skyblock, minez, survival, bedwars, duels, pvp",
//   meta: {
//     name: {
//       keywords: "jbwm,serwery,minecraft,skyblock",
//     },
//   },
// };

function Homepage() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let { width } = windowDimensions;

  return (
    <>
      {/* <DocumentMeta {...meta} /> */}
      {/* <Broadcast /> */}
      <Herosection />
      
      <section className="w-100 py-5 mt-5">
        <SocialMedia width={width} />
      </section>

      <section className="w-100 py-5 mt-4">
        <ServerInfo />
      </section>
    </>
  );
}

export default Homepage;
