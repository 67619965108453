import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Top10 from "../../components/stats/Top10";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styles from "./stats.module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Stats({ gameMode }) {
  const navigate = useNavigate();

  const [top10, setTop10] = useState();

  useEffect(() => {
    fetch(`https://backend.jbwm.pl/api/stats/${gameMode.toLowerCase()}/rank`)
      .then((response) => response.json())
      .then((data) => {
        setTop10(data);
      });
  }, [gameMode]);

  const [searchedUser, setSearchedUser] = useState("");

  const top10SingleClassName = "col-12 col-lg-6 px-md-3";

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 my-5">
      <div className={`${styles.searchContainer} mb-5`}>
        <form
          className="d-flex flex-row justify-content-center align-items-stretch w-100"
          onSubmit={(e) => {
            e.preventDefault();
            if (searchedUser.trim()) {
              navigate(`/statystyki-${gameMode.toLowerCase()}/${searchedUser}`);
            }
          }}
        >
          <input
            type="text"
            value={searchedUser}
            placeholder="Wyszukaj gracza..."
            className={`${styles.searchBox} w-100 border-0 rounded-start`}
            onChange={(event) => {
              setSearchedUser(event.target.value);
            }}
          />

          <button
            type="submit"
            className={`${styles.searchButton} border-0 rounded-end d-flex align-items-center justify-content-center`}
            disabled={!searchedUser.trim()}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
      </div>

      <h1 className="fancy text-white my-5">
        <span>TOP 10 {gameMode}</span>
      </h1>

      <div className="d-flex flex-wrap justify-content-center w-75">
        {top10
          ? Object.entries(top10["topRanking"]).map(([key, value], index) => {
              return (
                <div key={index} className={top10SingleClassName}>
                  <Top10 category={top10["translations"][key]} users={value} />
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default Stats;
