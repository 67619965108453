import { useNavigate } from "react-router-dom";
import styles from "./GamemodeInfo.module.scss";

function GamemodeInfo({ title, type, src, destination }) {
    const navigation = useNavigate();

    return (
        <div 
            className={styles.gamemodeInfo}
            onClick={() => navigation(destination)}
        >
            {type === "image" ? (
                <img
                    src={src}
                    alt={title}
                />
            ) : (
                <video
                    autoPlay
                    muted
                    loop
                >
                    <source 
                        src={src}
                        type="video/mp4"
                    />
                </video>
            )}

            <div className={styles.gameTitle}>{title}</div>
        </div>
    );
}

export default GamemodeInfo;
