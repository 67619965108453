import React, { useState, useEffect } from "react";

function Tiktok({ vidId }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Reset loaded state when vidId changes
    setLoaded(false);
    // Set a timeout to ensure TikTok has time to load
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, [vidId]);

  const iframeStyle = {
    width: "100%",
    height: "600px",
    display: "block",
    visibility: loaded ? "visible" : "hidden",
    border: "none",
    // Keep scrollbar styling but allow scrolling
    scrollbarWidth: "none", /* Firefox */
    msOverflowStyle: "none", /* IE and Edge */
  };

  // Apply scrollbar hiding via javascript for iframes
  useEffect(() => {
    if (loaded) {
      // Try to access iframe document to hide scrollbars
      try {
        const iframes = document.querySelectorAll('iframe[title^="tiktok-"]');
        iframes.forEach(iframe => {
          if (iframe.contentDocument) {
            const style = document.createElement('style');
            style.textContent = `
              ::-webkit-scrollbar { width: 6px !important; background: transparent !important; }
              ::-webkit-scrollbar-thumb { background: rgba(255, 255, 255, 0.2) !important; border-radius: 10px !important; }
              * { scrollbar-width: thin !important; scrollbar-color: rgba(255, 255, 255, 0.2) transparent !important; }
            `;
            iframe.contentDocument.head.appendChild(style);
          }
        });
      } catch (e) {
        // Cross-origin restrictions may prevent this
        console.log("Could not modify iframe scrollbars due to security restrictions");
      }
    }
  }, [loaded]);

  return (
    <div style={{ 
      position: "relative", 
      width: "100%", 
      minHeight: "600px",
      // Allow scrolling but keep scrollbars styled
      overflow: "auto"
    }}>
      <iframe
        title={`tiktok-${vidId}`}
        loading="lazy"
        src={`https://www.tiktok.com/embed/v2/${vidId}?lang=pl-PL`}
        style={iframeStyle}
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        onLoad={() => setLoaded(true)}
        // Remove scrolling="no" to enable scrolling
      />
      {!loaded && (
        <div 
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: "10px",
          }}
        >
          <span style={{ color: "#fff" }}>Ładowanie TikToka...</span>
        </div>
      )}
    </div>
  );
}

export default Tiktok;
