import styles from "./top10.module.scss";
import capitalizeFirstLetter from "../../utils/functions";
import { useNavigate, useParams } from "react-router-dom";

function Top10({ category, users }) {
  const navigate = useNavigate();
  const { gameMode } = useParams();
  
  // If gameMode is not available from params, try to extract it from URL
  const getCurrentGameMode = () => {
    if (gameMode) return gameMode;
    
    const currentPath = window.location.pathname;
    if (currentPath.includes('minez')) return 'minez';
    if (currentPath.includes('skymmo')) return 'skymmo';
    return 'SkyMMO'; // Default fallback
  };
  
  const handlePlayerClick = (username) => {
    const currentGameMode = getCurrentGameMode();
    navigate(`/statystyki-${currentGameMode.toLowerCase()}/${username}`);
  };

  return users ? (
    <div className="d-flex flex-column justify-content-center align-items-center text-white w-100 my-5">
      <h2 className={`${styles.category} mb-5`}>
        {capitalizeFirstLetter(category)}
      </h2>

      <div className={`${styles.container} w-100`}>
        {Object.entries(users).map(([key, value], index) => {
          // Determine if this is a top-3 rank
          const isTopThree = index < 3;
          const rankClass = isTopThree ? `${styles.topRank} ` : '';
          
          // Add specific styling for top 3 positions
          let medalClass = '';
          if (index === 0) medalClass = styles.rank1;
          else if (index === 1) medalClass = styles.rank2;
          else if (index === 2) medalClass = styles.rank3;
          
          return (
            <div
              key={index}
              className={`${styles.record} d-flex flex-row justify-content-between align-items-center w-100 p-3`}
              onClick={() => handlePlayerClick(key)}
            >
              <div
                className={`${rankClass} ${medalClass} fw-bold text-center`}
                style={{
                  width: 50,
                }}
              >
                #{index + 1}
              </div>

              <div
                className="d-flex justify-content-center"
                style={{
                  width: 60,
                }}
              >
                <img 
                  className={styles.avatar}
                  src={`https://minotar.net/avatar/${key}/50`} 
                  alt={`${key}'s avatar`} 
                />
              </div>

              <div className={`${styles.username} w-25 text-center`}>
                {key}
              </div>

              <div className={`${styles.value} w-25 text-end`}>{value}</div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
}

export default Top10;
