import React from "react";
import GamemodeHeader from "../../components/gamemode-pages/GamemodeHeader";
import GamemodeName from "../../components/gamemode-pages/GamemodeName";
import GamemodeDescription from "../../components/gamemode-description/GamemodeDescription";

function Backrooms() {
  const sectionClassName = "d-flex flex-column flex-lg-row justify-content-center align-items-center w-75 my-5"
  const sectionItemClassName = "col-12 col-lg-6 mx-5 d-flex justify-content-center align-items-center"
  const descriptionSectionItemClassName = `${sectionItemClassName} d-flex flex-column justify-content-center align-items-center`
  const imgClassName = "col-12 col-lg-8"

  return <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <GamemodeName name={"BACKROOMS"} />

    <GamemodeHeader
      heading={
        `Eksploruj nieskończone, niekończące się poziomy Backroomsów, gdzie czas i przestrzeń tracą znaczenie. 
        Odkryj tajemnice ukryte w labiryntach żółtych ścian i zmagaj się z nadprzyrodzonymi istotami.`
      }
      subHeading={
        `Unikalny tryb gry z autorskimi mechanikami, który przeniesie Cię do alternatywnej rzeczywistości pełnej tajemnic i niebezpieczeństw.
        Eksploruj różne poziomy, zbieraj zasoby, uciekaj przed potworami i spróbuj znaleźć drogę powrotną do normalnego świata - 
        jeśli w ogóle istnieje wyjście...`
      }
    />

    <div className="d-flex flex-row justify-content-center align-items-center w-100 py-5">
      <video autoPlay muted loop
        className="col-12 col-md-8 col-lg-6"
        style={{
          WebkitBoxShadow: "0px 0px 14px 0px black",
          BoxShadow: "0px 0px 14px 0px black"
        }}
      >
        <source src={require("../../assets/vids/backrooms.mp4")}
          type="video/mp4" />
      </video>
    </div>

    <div className={sectionClassName}>
      <div className={sectionItemClassName}>
        <img src={require("../../assets/img/albert.webp")}
          alt="Albert entity from Backrooms"
          className={imgClassName}
        />
      </div>

      <div className={descriptionSectionItemClassName}>
        <GamemodeDescription 
          header="EKSPLORUJ RÓŻNE POZIOMY!"
          description={
            `Każdy poziom Backroomsów ma swoje unikalne cechy, zagrożenia i tajemnice.
            Odkrywaj nowe lokacje, zbieraj zasoby i szukaj przejść między poziomami,
            aby zagłębić się dalej w tę niekończącą się strukturę.`
          }
        />

        <GamemodeDescription 
          header="STRZEŻ SIĘ POTWORÓW!"
          description={
            `Nie jesteś sam w Backroomsach. Różne stworzenia, od tajemniczych cieni po 
            przerażające byty, czyhają w ciemnych korytarzach. Staraj się pozostać w ciszy
            lub przygotuj się do walki z nadprzyrodzonymi istotami.`
          }
        />
      </div>
    </div>

    <div className={sectionClassName}>
      <div className={descriptionSectionItemClassName}>
        <GamemodeDescription
          header="ZNAJDŹ UKRYTE PRZEJŚCIA!"
          description={
            `W Backroomsach istnieją tajemnicze przejścia, które mogą przenieść Cię
            między poziomami lub nawet do bezpiecznych stref. Nauczysz się rozpoznawać
            znaki i anomalie, które prowadzą do tych sekretnych portali.`
          }
        />

        <GamemodeDescription 
          header="ODNAJDŹ UKRYTE ZASOBY!"
          description={
            `Aby przetrwać, musisz zbierać różne przedmioty porozrzucane po poziomach.
            Znajdziesz jedzenie, napoje, leki, a nawet tajemnicze artefakty, które mogą
            dać Ci nadprzyrodzone zdolności lub pomóc w odnalezieniu drogi do domu.`
          } />
      </div>

      <div className={sectionItemClassName}>
        <img src={require("../../assets/img/crawler.webp")}
          alt="Crawler entity from Backrooms"
          className={imgClassName}
        />
      </div>
    </div>
  </div>
}

export default Backrooms; 