import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faHome, 
  faCoins, 
  faChartBar, 
  faMap, 
  faFileAlt, 
  faBars, 
  faTimes 
} from "@fortawesome/free-solid-svg-icons";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return { width };
}

function Navbar() {
  const [online, setOnline] = useState("");
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = windowDimensions;
  
  const isMobile = width < 1024;

  async function fetchData() {
    try {
      const response = await fetch("https://mcapi.jbwm.pl/online");
      const data = await response.json();
      setOnline(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    
    // Set up interval to update player count every minute
    const interval = setInterval(fetchData, 60000);

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
      clearInterval(interval);
    };
  }, []);

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);
  const closeMobileMenu = () => setMobileMenuOpen(false);

  const navigateTo = (path) => {
    navigate(path);
    closeMobileMenu();
  };

  const isActive = (path) => {
    if (path === '/') return location.pathname === '/';
    return location.pathname.includes(path);
  };

  const navItems = [
    { icon: faHome, text: "Strona główna", path: "/" },
    { icon: faCoins, text: "Sklep", path: "https://sklep.jbwm.pl", external: true, newTab: false },
    { icon: faChartBar, text: "Statystyki", path: "/statystyki" },
    { icon: faMap, text: "Mapa MineZ", path: "/mapa-minez" },
    { icon: faMap, text: "Mapa SkyMMO", path: "/mapa-skymmo" },
    { icon: faFileAlt, text: "Regulamin", path: "/regulamin" }
  ];

  // Handle both internal and external navigation
  const handleNavigation = (item) => {
    if (item.external) {
      window.location.href = item.path;
    } else {
      navigateTo(item.path);
    }
    closeMobileMenu();
  };

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.navbarContent}>
        <div className={styles.logoSection}>
          <img
            src={require("../../assets/img/logo.webp")}
            alt="JBWM Logo"
            onClick={() => navigateTo("/")}
            className={styles.logo}
          />

          <div className={styles.onlineInfo}>
            <div className={styles.pulse} />
            <span>Online: {online} </span>
          </div>
        </div>

        {isMobile && (
          <button 
            className={styles.mobileMenuButton} 
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            <FontAwesomeIcon icon={mobileMenuOpen ? faTimes : faBars} />
          </button>
        )}

        <nav 
          className={`${styles.navigation} ${isMobile ? styles.mobileNav : ''} ${mobileMenuOpen ? styles.mobileNavOpen : ''}`}
        >
          <ul>
            {navItems.map((item, index) => (
              <li 
                key={index} 
                className={isActive(item.path) ? styles.active : ''}
              >
                <button 
                  onClick={() => handleNavigation(item)}
                  className={styles.navLink}
                >
                  <FontAwesomeIcon icon={item.icon} className={styles.navIcon} />
                  <span>{item.text}</span>
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
