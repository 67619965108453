import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faServer, 
  faUsers, 
  faCalendarAlt, 
  faCrown 
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/ServerInfo.module.scss";
import { motion } from "framer-motion";

const ServerInfo = () => {
  const serverInfo = [
    {
      icon: faCalendarAlt,
      title: "Nieprzerwanie od 2020",
      description: "Nasza sieć serwerów działa nieprzerwanie od 2020 roku, zapewniając stabilne doświadczenie dla tysięcy graczy"
    },
    {
      icon: faServer,
      title: "Najlepszy Skyblock",
      description: "Skymmo działa od 2022 roku i jest jedynym polskim Skyblockiem bez edycji, który zapewnia ciągłość rozgrywki"
    },
    {
      icon: faUsers,
      title: "Ponad 200 000 graczy",
      description: "Zbudowaliśmy społeczność liczącą ponad 200 tysięcy graczy, którzy zaufali naszej jakości i innowacjom"
    },
    {
      icon: faCrown,
      title: "Wyjątkowe Doświadczenie",
      description: "Tworzymy coś unikalnego na wiele godzin gry - tryby i mechaniki, których nie znajdziesz na innych serwerach"
    }
  ];

  return (
    <div className={`container mt-5 mb-5 ${styles.serverInfoContainer}`}>
      <motion.h2 
        className={styles.sectionTitle}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        Dlaczego warto wybrać nasze serwery?
      </motion.h2>
      
      <div className={styles.infoGrid}>
        {serverInfo.map((info, index) => (
          <motion.div 
            key={index} 
            className={styles.infoCard}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 * index }}
          >
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={info.icon} className={styles.icon} />
            </div>
            <h3 className={styles.infoTitle}>{info.title}</h3>
            <p className={styles.infoDescription}>{info.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ServerInfo; 