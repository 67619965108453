import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import TiktokSwiper from "./TiktokSwiper";
import styles from "../../styles/SocialMedia.module.scss";
import { motion } from "framer-motion";

const SocialMedia = ({ width }) => {
  const [activeTab, setActiveTab] = useState("facebook");
  const [fbLoaded, setFbLoaded] = useState(false);
  const [tiktoksLoaded, setTiktoksLoaded] = useState(false);
  const fbContainer = useRef(null);
  const tiktokContainer = useRef(null);
  const [fbKey, setFbKey] = useState(0);

  useEffect(() => {
    // Load Facebook SDK
    const loadFacebookSDK = () => {
      window.fbAsyncInit = function() {
        window.FB.init({
          xfbml: true,
          version: 'v16.0'
        });
        setFbLoaded(true);
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v16.0";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };

    loadFacebookSDK();
  }, []);

  // Re-parse Facebook content when switching back to the Facebook tab
  useEffect(() => {
    if (activeTab === 'facebook') {
      // Reset loading state
      setFbLoaded(false);
      
      // Force remount of the Facebook component by changing key
      setFbKey(prevKey => prevKey + 1);
      
      // Re-parse Facebook content if FB SDK is loaded
      if (window.FB) {
        setTimeout(() => {
          window.FB.XFBML.parse(fbContainer.current);
          // Set a timeout to mark as loaded even if parsing takes too long
          setTimeout(() => {
            setFbLoaded(true);
          }, 2000);
        }, 100);
      }
    }
  }, [activeTab]);

  // Pre-load TikToks when component mounts
  useEffect(() => {
    if (activeTab === 'tiktok') {
      // Give time for the TikTok content to render
      const timer = setTimeout(() => {
        setTiktoksLoaded(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [activeTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'tiktok') {
      setTiktoksLoaded(false);
      // Reset loading state when switching to TikTok
      setTimeout(() => {
        setTiktoksLoaded(true);
      }, 1000);
    }
  };

  return (
    <motion.div 
      className={styles.socialMediaSection}
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className="container">
        <div className="text-center">
          <h2 className={styles.sectionTitle}>Zobacz co u nas!</h2>
          <div className={styles.divider}></div>
          <p className={styles.sectionSubtitle}>
            Śledź nas w mediach społecznościowych, aby być na bieżąco z nowościami, 
            wydarzeniami i konkursami na naszych serwerach!
          </p>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.socialTabs}>
            <button 
              className={`${styles.socialTab} ${activeTab === 'facebook' ? styles.active : ''}`} 
              onClick={() => handleTabChange('facebook')}
            >
              <FontAwesomeIcon icon={faFacebookF} className={styles.platformIcon} />
              Facebook
            </button>
            <button 
              className={`${styles.socialTab} ${activeTab === 'tiktok' ? styles.active : ''}`} 
              onClick={() => handleTabChange('tiktok')}
            >
              <FontAwesomeIcon icon={faTiktok} className={styles.platformIcon} />
              TikTok
            </button>
          </div>

          <div className={styles.content}>
            {activeTab === 'facebook' && (
              <motion.div 
                key={fbKey}
                className={styles.facebookContainer}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}
                ref={fbContainer}
              >
                <div className="fb-page" 
                  data-href="https://www.facebook.com/Jbwmpl/" 
                  data-tabs="timeline" 
                  data-width={width < 556 ? "300" : "500"} 
                  data-height={width < 556 ? "500" : "700"} 
                  data-small-header="true" 
                  data-adapt-container-width="false" 
                  data-hide-cover="true" 
                  data-show-facepile="false">
                  <blockquote cite="https://www.facebook.com/Jbwmpl/" className="fb-xfbml-parse-ignore">
                    <a href="https://www.facebook.com/Jbwmpl/">JBWM.PL</a>
                  </blockquote>
                </div>
                {!fbLoaded && (
                  <div className={styles.loaderContainer}>
                    <div className={styles.loader}></div>
                    <p>Ładowanie zawartości Facebook...</p>
                  </div>
                )}
              </motion.div>
            )}

            {activeTab === 'tiktok' && (
              <motion.div 
                className={styles.tiktokContainer}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}
                ref={tiktokContainer}
              >
                <TiktokSwiper />
                {!tiktoksLoaded && (
                  <div className={styles.loaderContainer}>
                    <div className={styles.loader}></div>
                    <p>Ładowanie TikToków...</p>
                  </div>
                )}
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SocialMedia; 